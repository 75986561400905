import {
    FETCH_DAYS,
    FETCH_DAYS_SUCCESS,
    FETCH_DAYS_FAILURE,

    FETCH_DAYS_BY_ARGS,
    FETCH_DAYS_BY_ARGS_SUCCESS,
    FETCH_DAYS_BY_ARGS_FAILURE,

    FETCH_VERSE_BY_ID,
    FETCH_VERSE_BY_ID_SUCCESS,
    FETCH_VERSE_BY_ID_FAILURE,
    UPDATE_DAY

} from '../constants/bible';
import { error as notificationError } from 'react-notification-system-redux';
import {
    fetchDaysService, fetchVerseService, fetchVerseByIdService
} from '../../services/verses-api';
import { ActionCreatorFactory } from '../methods';

const fetchDays = () => ActionCreatorFactory(FETCH_DAYS);
const fetchDaysSuccess = data => ActionCreatorFactory(FETCH_DAYS_SUCCESS, data);
const fetchDaysError = error => ActionCreatorFactory(FETCH_DAYS_FAILURE, error);

export function getDays(day = 1) {
    return (dispatch) => {
        dispatch(fetchDays());
        fetchDaysService(day)
            .then((response) => {
                if (response.status !== 200) {
                    dispatch(fetchDaysError(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(fetchDaysSuccess(response.data))
            })
            .catch((error) => {
                dispatch(fetchDaysError(error));
                dispatch(notificationError({
                    'title': error?.response?.data?.message ||
                        error?.request?.statusText,
                    'message': `Failed to load day`,
                }));
            })
    };
}


const fetchByArgsDays = () => ActionCreatorFactory(FETCH_DAYS_BY_ARGS);
const fetchDaysByArgsSuccess = data => ActionCreatorFactory(FETCH_DAYS_BY_ARGS_SUCCESS, data);
const fetchDaysByArgsError = error => ActionCreatorFactory(FETCH_DAYS_BY_ARGS_FAILURE, error);

export function getDaysByArgs(args) {
    return (dispatch) => {
        dispatch(fetchByArgsDays());
        fetchVerseService(args)
            .then((response) => {
                if (response.status !== 200) {
                    dispatch(fetchDaysByArgsError(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(fetchDaysByArgsSuccess(response.data))
            })
            .catch((error) => {
                dispatch(fetchDaysError(error));
                dispatch(fetchDaysByArgsError({
                    'title': error?.response?.data?.message ||
                        error?.request?.statusText,
                    'message': `Failed to load day`,
                }));
            })
 
        };
}

const updateDayAction = data => ActionCreatorFactory(UPDATE_DAY, data);

export function updateDay(day) {
	return (dispatch) => {
		dispatch(updateDayAction(day));
	}
}

const fetchVerseByIdDays = () => ActionCreatorFactory(FETCH_VERSE_BY_ID);
const fetchVerseByIdSuccess = data => ActionCreatorFactory(FETCH_VERSE_BY_ID_SUCCESS, data);
const fetchVerseByIdError = error => ActionCreatorFactory(FETCH_VERSE_BY_ID_FAILURE, error);

export function getVerseById(id) {
    return (dispatch) => {
        dispatch(fetchVerseByIdDays());
        fetchVerseByIdService(id)
            .then((response) => {
                if (response.status !== 200) {
                    dispatch(fetchVerseByIdError(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(fetchVerseByIdSuccess(response.data))
            })
            .catch((error) => {
                dispatch(fetchDaysError(error));
                dispatch(fetchVerseByIdError({
                    'title': error?.response?.data?.message ||
                        error?.request?.statusText,
                    'message': `Failed to fetch verse`,
                }));
            })
    };
}