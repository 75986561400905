import {
    FETCH_NOTES,
    FETCH_NOTES_SUCCESS,
    FETCH_NOTES_FAILURE,
    CREATE_NOTE,
    UPDATE_NOTE,
    DELETE_NOTE,

    FETCH_USER_TAGS,
    FETCH_USER_TAGS_SUCCESS,
    FETCH_USER_TAGS_FAILURE
} from '../constants/notes';
import { error as notificationError } from 'react-notification-system-redux';
import {
    fetchNotesService,
    deleteNoteService,
    fetchCurrentUserTagsService
} from '../../services/notes-api';
import { ActionCreatorFactory } from '../methods';
import { getVerseById } from './bible';

const fetchNotes = () => ActionCreatorFactory(FETCH_NOTES);
const fetchNotesSuccess = data => ActionCreatorFactory(FETCH_NOTES_SUCCESS, data);
const fetchNotesError = error => ActionCreatorFactory(FETCH_NOTES_FAILURE, error);

export function getNotes(params) {
    return (dispatch) => {
        dispatch(fetchNotes());
        fetchNotesService(params)
            .then((response) => {
                if (response.status !== 200) {
                    dispatch(fetchNotesError(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(fetchNotesSuccess(response.data))
            })
            .catch((error) => {
                dispatch(fetchNotesError(error));
                dispatch(notificationError({
                    'title': error?.response?.data?.message ||
                        error?.request?.statusText,
                    'message': `Failed to load notes`,
                }));
            })
    };
}

const addNoteAction = data => ActionCreatorFactory(CREATE_NOTE, data);

export function addNote(note) {
    return (dispatch) => {
        dispatch(addNoteAction(note));
    }
}

const updateNoteAction = data => ActionCreatorFactory(UPDATE_NOTE, data);

export function updateNote(note) {
    return (dispatch) => {
        dispatch(updateNoteAction(note));
    }
}

const removeNoteAction = data => ActionCreatorFactory(DELETE_NOTE, data);

export const deleteNote = noteMeta => {
    return (dispatch) => {
        deleteNoteService(noteMeta.id)
            .then((response) => {
                dispatch(removeNoteAction(noteMeta));
                dispatch(getVerseById(noteMeta.verse.id))
            })
            .catch((error) => {
                dispatch(notificationError({
                    'title': error?.response?.data?.message ||
                        error?.request?.statusText,
                    'message': `Failed to delete note`,
                }));
            })
    }
}

const fetchUserTags = () => ActionCreatorFactory(FETCH_USER_TAGS);
const fetchUserTagsSuccess = data => ActionCreatorFactory(FETCH_USER_TAGS_SUCCESS, data);
const fetchUserTagsError = error => ActionCreatorFactory(FETCH_USER_TAGS_FAILURE, error);

export function getUserTags() {
    return (dispatch) => {
        dispatch(fetchUserTags());
        fetchCurrentUserTagsService()
            .then((response) => {
                if (response.status !== 200) {
                    dispatch(fetchUserTagsError(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(fetchUserTagsSuccess(response.data))
            })
            .catch((error) => {
                dispatch(fetchUserTagsError(error));
                dispatch(notificationError({
                    'title': error?.response?.data?.message ||
                        error?.request?.statusText,
                    'message': `Failed to load tags`,
                }));
            })
    };
}