export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const USER_SIGNUP = 'USER_SIGNUP';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_FAILURE = 'USER_SIGNUP_FAILURE';

export const LOAD_CURRENT_USER = 'LOAD_CURRENT_USER';
export const LOAD_CURRENT_USER_SUCCESS = 'LOAD_CURRENT_USER_SUCCESS';
export const LOAD_CURRENT_USER_FAILURE = 'LOAD_CURRENT_USER_FAILURE';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

export const RELOAD_USER = 'RELOAD_USER';
export const RELOAD_USER_SUCCESS = 'RELOAD_USER_SUCCESS';
export const RELOAD_USER_FAILURE = 'RELOAD_USER_FAILURE';

export const INITIATE_AUTH_CLEANUP = 'INITIATE_AUTH_CLEANUP';

export const ACTIONS = {
	USER_LOGIN,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAILURE,
	LOAD_CURRENT_USER,
	LOAD_CURRENT_USER_SUCCESS,
	LOAD_CURRENT_USER_FAILURE,
	USER_SIGNUP,
	USER_SIGNUP_SUCCESS,
	USER_SIGNUP_FAILURE,
	USER_LOGOUT,
	USER_LOGOUT_SUCCESS,
	USER_LOGOUT_FAILURE,
	INITIATE_AUTH_CLEANUP,
	RELOAD_USER,
	RELOAD_USER_SUCCESS,
	RELOAD_USER_FAILURE,
};