export const FETCH_NOTES = 'FETCH_NOTES';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';
export const FETCH_NOTES_FAILURE = 'FETCH_NOTES_FAILURE';

export const DELETE_NOTE = 'DELETE_NOTE';
export const CREATE_NOTE = 'CREATE_NOTE';
export const UPDATE_NOTE = 'UPDATE_NOTE';

export const FETCH_USER_TAGS = 'FETCH_USER_TAGS';
export const FETCH_USER_TAGS_SUCCESS = 'FETCH_USER_TAGS_SUCCESS';
export const FETCH_USER_TAGS_FAILURE = 'FETCH_USER_TAGS_FAILURE';

export const ACTIONS = {
    FETCH_NOTES,
    FETCH_NOTES_SUCCESS,
    FETCH_NOTES_FAILURE,
    CREATE_NOTE,
    UPDATE_NOTE,
    DELETE_NOTE,
};