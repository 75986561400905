import React, { lazy } from 'react';
import { CircularLoader } from '../components/Common/Loaders';

const Days = lazy(() => import('../components/Bible/Days'));

export default function Home() {
    return (
        <div>
            <React.Suspense fallback={<CircularLoader />}>
                <Days />
            </React.Suspense>
        </div>
    )
}
