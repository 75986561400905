export const FETCH_DAYS = 'FETCH_DAYS';
export const FETCH_DAYS_SUCCESS = 'FETCH_DAYS_SUCCESS';
export const FETCH_DAYS_FAILURE = 'FETCH_DAYS_FAILURE';

export const FETCH_DAYS_BY_ARGS = 'FETCH_DAYS_BY_ARGS';
export const FETCH_DAYS_BY_ARGS_SUCCESS = 'FETCH_DAYS_BY_ARGS_SUCCESS';
export const FETCH_DAYS_BY_ARGS_FAILURE = 'FETCH_DAYS_BY_ARGS_FAILURE';

export const FETCH_VERSE_BY_ID = 'FETCH_VERSE_BY_ID';
export const FETCH_VERSE_BY_ID_SUCCESS = 'FETCH_VERSE_BY_ID_SUCCESS';
export const FETCH_VERSE_BY_ID_FAILURE = 'FETCH_VERSE_BY_ID_FAILURE';

export const UPDATE_DAY = 'UPDATE_DAY';