import {
    FETCH_DAYS,
    FETCH_DAYS_SUCCESS,
    FETCH_DAYS_FAILURE,

    FETCH_DAYS_BY_ARGS,
    FETCH_DAYS_BY_ARGS_SUCCESS,
    FETCH_DAYS_BY_ARGS_FAILURE,

    FETCH_VERSE_BY_ID_SUCCESS,
    UPDATE_DAY

} from '../constants/bible';
import { updateObjectInArrayWithId } from '../methods';

const INITIAL_STATE = {
    days: [],
    count: null,
    isLoading: false,
    isLoaded: false,
    searchByArgs: {
        results: [],
        count: null,
        isLoading: false,
        isLoaded: false,
    }
};

function notes(state = INITIAL_STATE, action) {

    switch (action.type) {
        case FETCH_DAYS:
            {
                return {
                    ...state,
                    isLoaded: false,
                    isLoading: true,
                }
            }
        case FETCH_DAYS_SUCCESS:
            {
                return {
                    ...state,
                    days: action.payload?.results || [],
                    count: (action.payload?.results || []).length,
                    isLoading: false,
                    isLoaded: true,
                }
            }
        case FETCH_DAYS_FAILURE:
            {
                return {
                    ...state,
                    isLoading: false,
                    isLoaded: true,
                }
            }
        case FETCH_DAYS_BY_ARGS:
            {
                return {
                    ...state,
                    searchByArgs: {
                        ...state.searchByArgs,
                        isLoaded: false,
                        isLoading: true,
                    }
                }
            }
        case FETCH_DAYS_BY_ARGS_SUCCESS:
            {
                return {
                    ...state,
                    searchByArgs: {
                        ...state.searchByArgs,
                        results: action.payload?.results || [],
                        count: (action.payload?.results || []).length,
                        isLoading: false,
                        isLoaded: true,
                    }
                }
            }
        case FETCH_DAYS_BY_ARGS_FAILURE:
            {
                return {
                    ...state,
                    searchByArgs: {
                        ...state.searchByArgs,
                        isLoading: false,
                        isLoaded: true,
                    }
                }
            }
            case FETCH_VERSE_BY_ID_SUCCESS:
            case UPDATE_DAY:
                {
                    return {
                        ...state,
                        days: updateObjectInArrayWithId(state.days, action.payload),
                        count: state.count,
                    }
                }
        default:
            return state;
    }
}

export default notes;