import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import MUILink from "@material-ui/core/Link";
import { orange } from '@material-ui/core/colors';

import logo from '../../assets/images/logos/bible.png'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        marginTop: 'calc(5% + 10px)',
        bottom: '0',
    },
    link: {
        color: 'black',
        textDecoration: 'none'
    },
    footerLogo: {
        maxWidth: 160,
        verticalAlign: 'middle',
    },
    footer: {
        color: orange[900],
        fontSize: "16px"
    },
    icons: {
        color: "orange"
    },
    iconsDescription: {
        color: "white",
    }
}));

export default function Footer() {
    const classes = useStyles();
    return (
        <Box mt={5} className={classes.root}>
            <Grid container>
                <Grid item xs={12} md={12} sm={12}>
                    <Typography variant="body2" className={classes.footer} align="center">
                        <MUILink
                            component="a"
                            className={classes.link}>
                            <img src={logo}
                                width="48"
                                height="48"
                                alt="Our Friendship Bible Study Logo" className={classes.footerLogo} />
                        </MUILink>
                        &nbsp; &nbsp; Our Friendship Bible Study {`© ${new Date().getFullYear()} `}
                    </Typography>
                    <Typography variant="body2" align="center">
                        &nbsp; &nbsp; Made with ❤️ by
                        <a className={classes.link}
                            target="_blank"
                            rel="noreferrer"
                            href="https://majdimahfoud.com/"
                        >
                            <b>&nbsp;Majdi</b>
                        </a>
                    </Typography>
                    <Typography style={{ 'display': 'none' }} variant="body2" className={classes.footer} align="center">
                        Developed by&nbsp;&nbsp;
                        <a href="https://majdimahfoud.com/">https://majdimahfoud.com/</a>
                        &nbsp;&nbsp; if you are the new developer and need some clarification
                        ragarding the project and the technologies used,
                        you may consult your employer and then contact me.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}
