import React, { lazy } from 'react';
import { CircularLoader } from '../components/Common/Loaders';

const SearchByArguments = lazy(() => import('../components/Bible/SearchByArguments'));
const SearchNotes = lazy(() => import('../components/Note/SearchNotes'));

export default function Search() {
    return (
        <div>
            <React.Suspense fallback={<CircularLoader />}>
                <SearchNotes />
            </React.Suspense>
            <React.Suspense fallback={<CircularLoader />}>
                <SearchByArguments />
            </React.Suspense>
        </div>
    )
}
