import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from '../Common/Loaders';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { changeDocumentTitle } from '../../util/methods';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import bible from '../../assets/books/kjvbible.pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    IconButton: {
        margin: theme.spacing(1),
    },
    grid: {
        padding: theme.spacing(2),
    },
    gridContainer: {
        width: 'unset',
    },
}));

export default function ReadBook(props) {

    changeDocumentTitle("Our Friendship Bible Study - Read Bible");
    const classes = useStyles();
    const { width } = useWindowDimensions();
    const [numPages, setNumPages] = useState(0);
    const [loadSuccess, setLoadSuccess] = useState(false)
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1.0);
    const bookWidth = (83 / 100) * width;

    function onDocumentLoadSuccess(meta) {
        const { numPages } = meta;
        setNumPages(numPages);
        setLoadSuccess(true);
    }

    function goToPage(e) {
        let { value } = e.target;
        value = parseInt(value);
        if (isNaN(parseInt(value)) || value <= 0) {
            value = 1;
        }
        if (value > numPages) {
            value = numPages;
        }
        setPageNumber(value);
    }

    const previousPage = () => {
        if (numPages > 1 && pageNumber > 1) {
            const page = pageNumber - 1;
            setPageNumber(page)
        }
    }

    const nextPage = () => {
        if (pageNumber < numPages) {
            const page = pageNumber + 1;
            setPageNumber(page)
        }
    }

    const zoomIn = () => {
        setScale(scale => scale + 0.2)
    }

    const zoomOut = () => {
        setScale(scale => scale - 0.2)
    }

    return (
        <div className={classes.root}>
            <Grid container className={classes.gridContainer} >
                <Grid item className={classes.grid} sm="12" md="12" xl="12" >
                    {loadSuccess &&
                        <React.Fragment>
                            <Typography align="center" >
                                <p>Page {pageNumber} of {numPages}</p>
                                <IconButton
                                    className={classes.margin}
                                    onClick={zoomOut}>
                                    <ZoomOutIcon titleAccess="Zoom out" />
                                </IconButton>
                                <IconButton
                                    className={classes.margin}
                                    onClick={zoomIn}>
                                    <ZoomInIcon titleAccess="Zoom in" />
                                </IconButton>
                                <IconButton
                                    className={classes.margin}
                                    disabled={pageNumber <= 1}
                                    onClick={previousPage}>
                                    <ArrowBackIosIcon titleAccess="Previous page" />
                                </IconButton>
                                <IconButton
                                    className={classes.margin}
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}>
                                    <ArrowForwardIosIcon titleAccess="Next page" />
                                </IconButton>
                                <TextField style={{ "width": "52px" }}
                                    disabled={pageNumber >= numPages || pageNumber < 0}
                                    label="Page"
                                    type="number"
                                    value={pageNumber}
                                    onChange={goToPage} />
                            </Typography>
                        </React.Fragment>
                    }
                </Grid>
                <Grid item className={classes.grid} sm="12" md="12" xl="12">
                    <Document
                        file={bible}
                        loading={<CircularLoader />}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber}
                            width={bookWidth}
                            scale={scale}
                        />
                    </Document>
                </Grid>
            </Grid>
        </div>
    )
}
