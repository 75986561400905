import teal from '@material-ui/core/colors/teal';
import { getCookie, setCookie } from '../util/Cookies';


const getTheme = () => {
    const theme = getCookie('AppTheme');
    return theme ? theme : 'light';
};

const defaultPaletteColors = {
    primary: {
        main: '#660099',
    },
    secondary: {
        main: '#FF6600',
    },
};
// tail and black {"primary":{"main":"#009688"},"secondary":{"main":"#009688"}}
// black and orange {"primary":{"main": "#232323"},"secondary":{"main": "#ef5350"}};
// orange #ff5722
const getPaletteColors = () => {
    const paletteColors = getCookie('PaletteColors');
    return paletteColors ? JSON.parse(paletteColors) : defaultPaletteColors;
};

const setThemeCookie = theme => setCookie('AppTheme', theme, 30);

const setPaletteColorsCookie = paletteColors => setCookie('PaletteColors', JSON.stringify(paletteColors), 30);

const hideNLPLP = () => setCookie('hideNLPLP', true, 30);

const NLPLPIsHidden = () => {
    const hidden = getCookie('hideNLPLP');
    return hidden ? hidden : false;
};

const themePrimaryColor = teal;
const darkModeOn = getTheme() === 'dark';
const theme = getTheme();
const paletteColors = getPaletteColors();


export {
    theme,
    setThemeCookie,
    defaultPaletteColors,
    setPaletteColorsCookie,
    paletteColors,
    darkModeOn,
    themePrimaryColor,
    hideNLPLP,
    NLPLPIsHidden
};