import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import MoreIcon from '@material-ui/icons/MoreVert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import SearchIcon from '@material-ui/icons/Search';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  title: {
    fontSize: 15,
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    notificationsMenu: {
      width: '100%',
      maxWidth: '80ch',
      backgroundColor: theme.palette.background.paper,
    },
  },
  AppBarButton: {
    textTransform: 'none',
    fontWeight: 'bold',
  },
  Avatar: {
    width: '25px',
    height: '25px',
  },
}));

function PrimaryAppBar(props) {

  const { currentUser, logout, isAuthenticated } = { ...props }
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuOpen = e => setMobileMoreAnchorEl(e.currentTarget);

  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

  const mobileMenuId = 'primary-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>

      <MenuItem key="Home">
        <IconButton
          aria-label="Home"
          component={RouterLink}
          to="/"
          color="inherit"
          className={classes.AppBarButton}>
          <HomeIcon />
        </IconButton>
        <p>Home</p>
      </MenuItem>
      <MenuItem key="Bible">
        <IconButton component={RouterLink}
          color="inherit"
          aria-label="Home"
          to="/bible"
          className={classes.AppBarButton}>
          <CollectionsBookmarkIcon />
        </IconButton>
        <p>Bible</p>
      </MenuItem>
      {isAuthenticated
        &&
        [
          <MenuItem key="Search">
            <IconButton
              aria-label="Search"
              component={RouterLink}
              to="/search"
              color="inherit"
              className={classes.AppBarButton}>
              <SearchIcon />
            </IconButton>
            <p>Search</p>
          </MenuItem>,
          <MenuItem key="Tags">
            <IconButton
              aria-controls="tags"
              component={RouterLink}
              to="/tags"
              color="inherit"
              className={classes.AppBarButton}>
              <LocalOfferIcon />
            </IconButton>
            <p>Tags</p>
          </MenuItem>,
          <MenuItem key="Logout">
            <IconButton
              color="inherit"
              className={classes.AppBarButtons}
              onClick={logout} >
              <ExitToAppIcon />
            </IconButton>
            <p>Logout</p>
          </MenuItem>

        ]
      }
      {isAuthenticated && currentUser.user.is_staff ?
        <MenuItem key="Admin">
          <IconButton
            color="inherit"
            component="a"
            target="_blank"
            rel="noreferrer"
            href="https://ourfriendshipbiblestudy.com/admin/"
            className={classes.AppBarButton}
          >
            <SupervisorAccountIcon />
          </IconButton>
          <p>Admin</p>
        </MenuItem>
        :
        null
      }
      {!isAuthenticated &&
        <MenuItem key="login">
          <IconButton
            aria-label="Login"
            component={RouterLink}
            to="/auth/signin"
            color="inherit">
            <ExitToAppIcon />
          </IconButton>
          <p>Login</p>
        </MenuItem>
      }
    </Menu >
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense" color="primary">
          <Typography variant="h4" noWrap>
            <Button
              component={RouterLink}
              color="inherit"
              variant="text"
              className={classes.title}
              to="/">
              Our Friendship Bible Study
              </Button>
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button component={RouterLink}
              key="Verses"
              color="inherit"
              className={classes.AppBarButton}
              to="/">
              Verses
            </Button>
            <Button component={RouterLink}
              key="Bible"
              color="inherit"
              className={classes.AppBarButton}
              to="/bible">
              Bible
            </Button>
            {isAuthenticated &&
              <React.Fragment>
                <Button component={RouterLink}
                  key="Search"
                  color="inherit"
                  className={classes.AppBarButton}
                  to="/search">
                  Search
                </Button>
                <Button component={RouterLink}
                  key="Tags"
                  to="/tags"
                  color="inherit"
                  className={classes.AppBarButton}>
                  Tags
                </Button>
                {currentUser.user.is_staff ?
                  <Button
                    key="Admin"
                    color="inherit"
                    component="a"
                    target="_blank"
                    rel="noreferrer"
                    href="https://ourfriendshipbiblestudy.com/admin/"
                    className={classes.AppBarButton}
                  >
                    Admin
                  </Button>
                  :
                  null
                }
                <Button
                  key="Logout"
                  color="inherit"
                  className={classes.AppBarButton}
                  onClick={logout}
                >
                  Logout
                </Button>
              </React.Fragment>
            }

            {!isAuthenticated &&
              <React.Fragment>
                <IconButton
                  key="SignIn"
                  aria-label="Login"
                  component={RouterLink}
                  to="/auth/signin"
                  color="inherit">
                  <ExitToAppIcon />
                </IconButton>
              </React.Fragment>
            }
          </div>
          <div className={classes.sectionMobile}>

            <IconButton
              key="sectionMobile"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit">
              <MoreIcon />
            </IconButton>

          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    isAuthenticated: state.auth.currentUser.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryAppBar);