import Home from '../containers/Home';
import SignIn from '../components/Auth/SignIn';
import SignUp from '../components/Auth/SignUp';
import Search from '../containers/Search';
import Tags from '../components/Note/Tags';
import ReadBook from '../components/Bible/ReadBook';
import NotFound from '../components/Errors/NotFound';


// these are our app routes and there respective components
const Routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        needsAuthentication: true,
        needsAuthorisation: false,
        permissions: [],
        fallback: {
            component: SignIn,
            props: {
                routeMessage: `Please login to access the Verses page`,
            }
        }
    },
    {
        path: '/search/:q?',
        name: 'Search',
        component: Search,
        needsAuthentication: true,
        needsAuthorisation: false,
        permissions: [],
    },
    {
        path: '/tags',
        name: 'Tags',
        component: Tags,
        needsAuthentication: true,
        needsAuthorisation: false,
        permissions: [],
    },
    {
        path: '/bible',
        name: 'Read Bible',
        component: ReadBook,
        needsAuthentication: false,
        needsAuthorisation: false,
        permissions: [],
    },
    {
        path: '/auth/signin',
        name: 'Sign in',
        component: SignIn,
        needsAuthentication: false,
        needsAuthorisation: false,
        permissions: [],
    },
    {
        path: '/auth/signup',
        name: 'Sign up',
        component: SignUp,
        needsAuthentication: false,
        needsAuthorisation: false,
        permissions: [],
    },
    {
        path: '/http-status/404',
        name: 'Not Found',
        component: NotFound,
        needsAuthentication: false,
        needsAuthorisation: false,
        permissions: [],
    },
];

export default Routes;