export const range = (start, stop, step = 1) =>
    Array.from({ length: (stop - start) / step }, (_, i) => start + (i * step));

export const truncate = (what, length = 100) =>
    what !== null && what !== undefined &&
    (what.length < length) ? what : what.substring(0, length) + '..';

export const changeDocumentTitle = title => window.document.title = title;

export const isLeap = () => new Date(new Date().getFullYear(), 1, 29).getDate() === 29;

export const parsePostTags = tagsString => {
    if(!tagsString) return [];
    const re = /\s*(?:,|$)\s*/;
    const test = tagsString.split(re);
    if(test === undefined){
        return []
    }
    return test;
  }
  
export function sortAlphaByTag(array) {
    return array.sort((a, b) => a.tags.localeCompare(b.tags))
}