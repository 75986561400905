import api from './api';

export const fetchNotesService = params => api.get(`/notes/search`, { params: params });

export const createNoteService = formData => api.post(`/notes`, {...formData });

export const fetchCurrentUserTagsService = () => api.get(`/tags`);

export const editNoteService = formData => api.put(`/notes/${formData.id}`, {...formData });

export const deleteNoteService = id => api.delete(`/notes/${id}`);