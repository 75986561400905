import {
    FETCH_NOTES,
    FETCH_NOTES_SUCCESS,
    FETCH_NOTES_FAILURE,
    CREATE_NOTE,
    UPDATE_NOTE,
    DELETE_NOTE,

    FETCH_USER_TAGS,
    FETCH_USER_TAGS_SUCCESS,
    FETCH_USER_TAGS_FAILURE
} from '../constants/notes';

import { updateObjectInArrayWithId, removeItemFromArray } from '../methods';

const INITIAL_STATE = {
    notes: [],
    count: 0,
    current: null,
    pageSize: null,
    totalPages: null,
    links: {
        previous: null,
        next: null,
    },
    isLoading: false,
    isLoaded: false,
    tags: {
        results: [],
        count: 0,
        isLoading: false,
        isLoaded: false,
    }
};

function notes(state = INITIAL_STATE, action) {

    switch (action.type) {
        case FETCH_NOTES:
            {
                return {
                    ...state,
                    isLoaded: false,
                    isLoading: true,
                }
            }
        case FETCH_NOTES_SUCCESS:
            {
                const { results = [], count, links, current, page_size, total_pages } = action.payload;
                return {
                    ...state,
                    notes: results,
                    count: count,
                    previous: links.previous,
                    next: links.next,
                    current: current,
                    pageSize: page_size,
                    totalPages: total_pages,
                    isLoading: false,
                    isLoaded: true, 
                }
            }
        case FETCH_NOTES_FAILURE:
            {
                return {
                    ...state,
                    isLoading: false,
                    isLoaded: true,
                }
            }
        case UPDATE_NOTE:
        case CREATE_NOTE: 
            {
                const note = action.payload;
                return {
                    ...state,
                    notes: updateObjectInArrayWithId(state.notes, note)
                }
            }
        case DELETE_NOTE: 
            {
                const deletedNoteId = action.payload.id;
                return {
                    ...state,
                    notes: removeItemFromArray(state.notes, deletedNoteId)
                }
            }
        case FETCH_USER_TAGS:
            {
                return {
                    ...state,
                    tags: {
                        ...state.tags,
                        isLoaded: false,
                        isLoading: true,
                    }
                }
            }
        case FETCH_USER_TAGS_SUCCESS:
            {
                return {
                    ...state,
                    tags: {
                        results: action.payload?.results || [],
                        count: (action.payload?.results || []).length,
                        isLoading: false,
                        isLoaded: true,
                    }
                }
            }
        case FETCH_USER_TAGS_FAILURE:
            {
                return {
                    ...state,
                    tags: {
                        ...state.tags,
                        isLoading: false,
                        isLoaded: true,
                    }
                }
            }
        default:
            return state;
    }
}

export default notes;