import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import { getUserTags } from '../../redux/actions/notes';
import { parsePostTags, changeDocumentTitle } from '../../util/methods';
import { makeStyles } from '@material-ui/core/styles';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { CircularLoader } from '../Common/Loaders';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
    },
    buttonLinkRoot: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    title: {
        fontSize: 14,
    },
    gridContainerContent: {
        padding: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(1),
        overflow: 'auto'
    },
    tag: {
        color: theme.palette.secondary.main,
        fontSize: theme.spacing(2.5),
    }
}));

function Tags(props) {

    changeDocumentTitle("Our Friendship Bible Study - Tags");
    const classes = useStyles();
    const { tags, loadTags } = props;
    const { height } = useWindowDimensions();
    const [sortedTags, setSortedTags] = useState([]);
    const tagsListMaxHeight = (80 / 100) * height;

    useEffect(() => {
        loadTags();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function sortTags() {
        // return the tags string
        const allTagsStrings = tags.results.map(({ tags }) => tags)
        // parse the tag string into an array: 'tag1, tag2' => ['tag1', 'tag2']
        const allParsedStringsTags = allTagsStrings.map(tagString => parsePostTags(tagString))
        // we may have many arrays: ['tag1', 'tag2'], ['tagx', 'tagy'] =>
        // ['tag1', 'tag2', 'tagx', 'tagy']
        const flattenedTagsArray = allParsedStringsTags.flat();
        // we may have duplicates ['tag1', 'tag2', 'tagx', 'tagy', 'tag2']
        // we remove them
        const allTagsWithDuplicatesRemoved = Array.from(new Set(flattenedTagsArray))
        // we sort alphabetically with case-insensitive
        const parsedSortedTags = allTagsWithDuplicatesRemoved.sort((a, b) =>
            a.localeCompare(b, 'en', { sensitivity: 'base' }))
        setSortedTags(parsedSortedTags)
    }

    useEffect(() => {
        if (tags.isLoaded && tags.results.length > 0) sortTags();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags.isLoaded, tags.results])

    return (
        <React.Fragment>
            <Grid container spacing={2} className={classes.gridContainerContent}>
                <Grid item xl={12} md={12} sm={12} xs={12}>
                    <Paper className={classes.paper} style={{ 'maxHeight': tagsListMaxHeight }} >
                        <Typography align="center"
                            component="h1"
                            variant="h5"
                            style={{ 'paddingTop': '0.5rem' }}>
                            Tags ({sortedTags.length})
                        </Typography>
                        {tags.isLoading &&
                            <CircularLoader />
                        }
                        <List
                            id="tags-menu"
                            keepMounted
                            TransitionComponent={Fade}
                            PaperProps={{
                                style: {
                                    maxHeight: 48 * 5.5,
                                    width: '25ch',
                                },
                            }}
                        >
                            {tags.isLoaded && sortedTags.length > 0 ?
                                sortedTags.map((tag, i) => (
                                    <ListItem key={i}
                                        component={RouterLink} to={`/search/@tags:${tag}`} >
                                        <Typography
                                            className={classes.tag} >
                                            {tag}
                                        </Typography>
                                    </ListItem>
                                ))
                                :
                                <ListItem key="tagsNotFound">
                                    {"No Tags Found"}
                                </ListItem>
                            }
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        tags: state.notes.tags,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
        loadTags: () => dispatch(getUserTags()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tags);