import React, { useMemo, useEffect } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Drawer from './components/Layout/Drawer';
import Footer from './components/Layout/Footer';
import Routes from './routes';
import WiseRouter from 'react-wise-router';
import { defaultPaletteColors } from './config/ui';
import { connect } from 'react-redux';
import { getCurrentUser } from './redux/actions/auth';
import './App.css';
import 'react-quill/dist/quill.snow.css';
import Notifications from 'react-notification-system-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(0)
  },
}));

//Optional styling
const UINotificationsStyle = {
  NotificationItem: { // Override the notification item
    DefaultStyle: { // Applied to every notification, regardless of the notification level
      margin: '5px 5px 2px 1px'
    },
  }
};

function App(props) {

  const { isAuthenticated, notifications, loadUser, permissions } = props;
  const classes = useStyles();
  const theme = useMemo(() =>
    createMuiTheme({
      palette: {
        type: 'light',
        primary: defaultPaletteColors.primary,
        secondary: defaultPaletteColors.secondary,
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
      },
      typography: {
        fontSize: 14,
      },
      overrides: {
        // Style sheet name ⚛️
        MuiLink: {
          // Name of the rule
          root: {
            // Some CSS
            textDecoration: 'none',
          },
        },
      },
    }),
  [] );

  useEffect(() => {
    if(!isAuthenticated) loadUser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Grid component="main" className={classes.root}>
        <Router>
          <React.Fragment>
            <Drawer />
            <Notifications
              notifications={notifications}
              style={UINotificationsStyle} />
              <Switch>
                {Routes.map(route => (
                  <WiseRouter 
                    exact={true} 
                    path={route.path} 
                    key={route.name} 
                    isAuthenticated={isAuthenticated}
                    needsAuthentication={route.needsAuthentication} 
                    needsAuthorisation={route.needsAuthorisation}
                    routePermissions={route.permissions}
                    userPermissions={permissions}
                    redirectTo={route.redirectTo}
                    defaultRedirect='/'
                    component={route.component} 
                    fallback={route.fallback}
                    debug={true} />
                ))}
                <Redirect from="*" to="/opcode/404"/>
              </Switch>
              <Footer />
          </React.Fragment>
        </Router>
      </Grid>
    </MuiThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.currentUser.authenticated,
    permissions: state.auth.currentUser.user?.permissions || [],
    notifications: state.notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  loadUser: () => dispatch(getCurrentUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);